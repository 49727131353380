import request from '@/utils/request'


// 查询订单-商铺订单列表
export function listShop(query) {
  return request({
    url: '/order/shop/list',
    method: 'get',
    params: query
  })
}

// 查询订单-商铺订单分页
export function pageShop(query) {
  return request({
    url: '/order/shop/page',
    method: 'get',
    params: query
  })
}

// 查询订单-商铺订单详细
export function getShop(data) {
  return request({
    url: '/order/shop/detail',
    method: 'get',
    params: data
  })
}

// 新增订单-商铺订单
export function addShop(data) {
  return request({
    url: '/order/shop/add',
    method: 'post',
    data: data
  })
}

// 修改订单-商铺订单
export function updateShop(data) {
  return request({
    url: '/order/shop/edit',
    method: 'post',
    data: data
  })
}

export function signingShop(data) {
  return request({
    url: '/order/shop/signing',
    method: 'post',
    data: data
  })
}
export function closeShop(data) {
  return request({
    url: '/order/shop/close',
    method: 'post',
    data: data
  })
}


// 删除订单-商铺订单
export function delShop(data) {
  return request({
    url: '/order/shop/delete',
    method: 'post',
    data: data
  })
}
